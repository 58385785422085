import React from 'react'
import { Card, Text, IconButton } from 'theme-ui'
import { FaPhone, FaEnvelope, FaLocationArrow } from 'react-icons/fa'
import Section from '@components/Section'
import useSiteMetadata from '@helpers-blog/useSiteMetadata'

const ContactInfo = () => {
  const { phone, address, email } = useSiteMetadata()

  return (
    <Section aside title='Cancelación de Suscripción:'>
      <Card variant='paper'>
        <Text variant='p'>Si en algún momento deseas cancelar tu suscripción y dejar de recibir correos electrónicos de ANSES Blog, puedes hacerlo fácilmente haciendo clic en el enlace de "Cancelar suscripción" que se encuentra al final de nuestros correos electrónicos.</Text>
        <Text variant='p'>
        Gracias por Unirte a Nosotros:

Te agradecemos por unirte a la comunidad de ANSES Blog. Esperamos poder proporcionarte información valiosa y mantenernos en contacto contigo mientras te mantenemos al tanto de todo lo relacionado con ANSES Argentina. ¡Bienvenido a bordo!
        </Text>
        {phone && (
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaPhone />
            </IconButton>
            {phone}
          </Text>
        )}
        {email && (
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaEnvelope />
            </IconButton>
            {email}
          </Text>
        )}
        {address && (
          <Text>
            <IconButton variant='simple' role='presentation'>
              <FaLocationArrow />
            </IconButton>
            {address}
          </Text>
        )}
      </Card>
    </Section>
  )
}

export default ContactInfo

import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Beneficios de la Suscripción:' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
      - Recibe las últimas noticias de ANSES directamente en tu bandeja de entrada.
      </Text>
      <Text variant='p'>
- Mantente al tanto de los cambios en los programas y beneficios de ayuda social.
</Text>
<Text variant='p'>
- Accede a guías y consejos útiles para facilitar tus trámites con ANSES.
</Text>
<Text variant='p'>
- Sé el primero en conocer eventos y recursos exclusivos para nuestros suscriptores.
      </Text>
    </Card>
  </Section>
)

export default Commitment
